"use client";

interface CustomTextInputProps {
  label?: string;
  placeHolder?: string;
  type?: string;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  id?: string;
  value?: string;
}

export const CustomTextInput = ({
  label,
  placeHolder,
  type = "text",
  onChange,
  disabled = false,
  error,
  helperText,
  id,
  value,
}: CustomTextInputProps) => {
  return (
    <div>
      {label && (
        <label htmlFor={id} className={"mb-[0.3rem] " + (error ? "text-red-500" : "")}>
          {label}
        </label>
      )}
      <input
        type={type}
        placeholder={placeHolder || ""}
        onChange={onChange}
        disabled={disabled}
        id={id}
        className={
          "bg-white dark:bg-transparent dark:text-white rounded-md w-full h-[55px] outline-none text-[15px] px-[15px] placeholder:text-[#5a5a5a] border " +
          (error ? "border-red-500" : "border-[#dedede]")
        }
        value={value}
      />

      {error && helperText && <span className="mt-2 text-sm text-red-500">{helperText}</span>}
    </div>
  );
};
