"use client";
import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useRouter, useSearchParams } from "next/navigation";
import envConfig from "@/config/envConfig";
import { CustomTextInput } from "@/components/shared/CustomTextInput";
import ButtonLoader from "@/components/shared/ButtonLoader";
import { useMessage } from "@/components/providers/MessageProvider";

const PhoneNumberLogin = () => {
  const [formError, setFormError] = useState("");

  const router = useRouter();
  const redirectUrl = useSearchParams().get("r");
  const { showMessage } = useMessage();

  const formik = useFormik({
    initialValues: {
      phoneNumber: "",
    },
    validationSchema: Yup.object({
      phoneNumber: Yup.string().required("*Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setFormError("");
      try {
        const response = await fetch(`${envConfig.publicServerUrl}/api/auth/mobile`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            phoneNumber: values.phoneNumber,
          }),
          //   credentials: "include",
        });

        if (!response.ok) {
          const errorData = await response.json();
          setFormError(errorData?.error || "Invalid Phone Number!");
          return;
        }

        router.push(`/verify-otp?phoneNumber=${values.phoneNumber}&r=${redirectUrl}`);
      } catch (err: any) {
        setFormError(err?.message || "Something went wrong, Try again");
      }
    },
  });

  return (
    <form className="mt-6 login-form" onSubmit={formik.handleSubmit}>
      <div>
        <CustomTextInput
          placeHolder="Enter your phone number"
          id="phoneNumber"
          value={formik.values.phoneNumber}
          onChange={formik.handleChange}
          error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
          helperText={formik.touched.phoneNumber ? formik.errors.phoneNumber : undefined}
        />
      </div>

      {formError && (
        <div className="mt-4">
          <span className="block text-sm text-red-500">{formError}</span>
        </div>
      )}
      <button className="mt-6 login-btn" disabled={formik.isSubmitting}>
        {formik.isSubmitting ? <ButtonLoader /> : "Log In"}
      </button>
    </form>
  );
};

export default PhoneNumberLogin;
