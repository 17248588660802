"use client";

import Link from "next/link";
import { useFormik } from "formik";
import * as Yup from "yup";
import { redirect, useSearchParams } from "next/navigation";

import { CustomPasswordInput } from "@/components/shared/CustomPasswordInput";
import { CustomTextInput } from "@/components/shared/CustomTextInput";
import { Typography } from "@/components/shared/Typography";
import envConfig from "@/config/envConfig";
import { useState } from "react";
import ButtonLoader from "@/components/shared/ButtonLoader";
import { useTheme } from "@/components/providers/ThemeProvider";
import { useMessage } from "@/components/providers/MessageProvider";
import PhoneNumberLogin from "./PhoneNumberLogin";
import { useAuthContext } from "@/components/providers/AuthContextProvider";

export default function LoginPageView() {
  const [activeTab, setActiveTab] = useState("email");

  const [formError, setFormError] = useState("");

  const { showMessage } = useMessage();
  const { darkMode } = useTheme();
  const { user } = useAuthContext();
  const redirectUrl = useSearchParams().get("r");
  if (user) {
    redirect("/");
  }

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email().trim().required("*Required"),
      password: Yup.string().min(8, "Must be at least 8 characters").required("*Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setFormError("");
      try {
        const response = await fetch(`${envConfig.publicServerUrl}/api/users/login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: values.email,
            password: values.password,
          }),
          credentials: "include",
        });

        if (!response.ok) {
          const errorData = await response.json();
          setFormError(errorData?.errors[0]?.message || "Invalid credentials!");
          return;
        }

        showMessage("success", "Login successful!");
        if (redirectUrl) {
          window.location.replace(`${redirectUrl}`);
        } else {
          window.location.replace("/");
        }
      } catch (err: any) {
        setFormError(err?.message || "Something went wrong, Try again");
      }
    },
  });

  return (
    <div className="h-full">
      <div className="flex flex-col items-center justify-between gap-4 py-6 md:flex-row">
        <Link href={"/"} aria-label="Go to home page">
          <img
            className="w-[200px] h-[38px] lg:w-[240px] lg:h-[45px] object-cover"
            alt="Deshabhimani"
            src={!darkMode ? "/deshabhimanilogo.svg" : "/deshabhimanilogo-white.svg"}
          />
        </Link>

        <span className="text-[15px] dark:text-secondaryText">
          Don&#39;t have an account?{" "}
          <Link
            href={redirectUrl ? `/register?r=${redirectUrl}` : `/register`}
            className="text-[#FF0004] font-medium"
            aria-label="Go to signup page"
          >
            Sign up!
          </Link>
        </span>
      </div>
      <div className="flex lg:items-center lg:justify-center min-h-[75vh] pt-8">
        <div className="w-full max-w-[400px] mx-auto">
          <Typography variant="h4" className="text-center">
            Welcome Back
          </Typography>
          <span className="block text-center text-[#7E7E7E] dark:text-secondaryText text-[15px] mt-1">
            Login into your account
          </span>

          <div className="w-full mt-6">
            <div className="flex flex-col items-center justify-center gap-4 md:flex-row">
              <a
                href={`${envConfig.publicServerUrl}/oauth2/authorize`}
                className="block w-full md:w-auto"
                aria-label="Login via google"
              >
                <button
                  className="bg-white border border-[#DEDEDE] flex items-center gap-2 justify-center text-sm w-full md:w-[140px] h-[40px] rounded-md"
                  aria-label="Google"
                >
                  <img
                    src="/images/google.svg"
                    alt="google"
                    className="w-[20px] h-[20px] object-cover"
                    loading="lazy"
                  />
                  Google
                </button>
              </a>
              <a
                href={`${envConfig.publicServerUrl}/oauth/facebook`}
                className="block w-full md:w-auto"
                aria-label="Login via facebook"
              >
                <button
                  className="bg-white border border-[#DEDEDE] flex items-center gap-2 justify-center text-sm w-full md:w-[140px] h-[40px] rounded-md"
                  aria-label="Facebook"
                >
                  <img
                    src="/images/facebook.svg"
                    alt="facebook"
                    className="w-[20px] h-[20px] object-cover"
                    loading="lazy"
                  />
                  Facebook
                </button>
              </a>
            </div>

            <div className="flex items-center gap-3 mt-4">
              <span className="block h-[1px] bg-[#DBDBDB] flex-1"></span>
              <span className="block text-center text-[15px] dark:text-secondaryText">
                Or continue with
              </span>
              <span className="block h-[1px] bg-[#DBDBDB] flex-1"></span>
            </div>
          </div>

          <div className="flex border-b mt-6">
            <button
              className={`w-1/2 py-2 text-center ${
                activeTab === "email" ? "border-b-2 border-blue-500 text-blue-500" : "text-gray-500"
              }`}
              onClick={() => setActiveTab("email")}
            >
              Login with Email
            </button>
            <button
              className={`w-1/2 py-2 text-center ${
                activeTab === "phone" ? "border-b-2 border-blue-500 text-blue-500" : "text-gray-500"
              }`}
              onClick={() => setActiveTab("phone")}
            >
              Login with Phone
            </button>
          </div>

          {activeTab === "email" ? (
            <form aria-live="polite" className="mt-6 login-form" onSubmit={formik.handleSubmit}>
              <div>
                <CustomTextInput
                  placeHolder="Enter Email"
                  id="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email ? formik.errors.email : undefined}
                />
              </div>
              <div className="mt-4">
                <CustomPasswordInput
                  placeHolder="Password"
                  id="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password ? formik.errors.password : undefined}
                />
              </div>
              <div className="flex items-center justify-between gap-4 mt-4">
                <div className="flex items-center gap-2">
                  <input type="checkbox" id="remember-me" />
                  <label htmlFor="remember-me" className="text-sm dark:text-white">
                    Remember me
                  </label>
                </div>
                <Link
                  href={redirectUrl ? `/forgot-password?r=${redirectUrl}` : `/forgot-password`}
                  className="text-sm text-[#D93F21]"
                  aria-label="Go to forgot password page"
                >
                  <span>Forgot Password?</span>
                </Link>
              </div>
              {formError && (
                <div className="mt-4">
                  <span className="block text-sm text-red-500">{formError}</span>
                </div>
              )}
              <button className="mt-6 login-btn" disabled={formik.isSubmitting}>
                {formik.isSubmitting ? <ButtonLoader /> : "Log In"}
              </button>
            </form>
          ) : (
            <div aria-live="polite" className="">
              <PhoneNumberLogin />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
