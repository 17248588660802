"use client";

import { createContext, useState, useEffect, useContext } from "react";

import envConfig from "@/config/envConfig";
import { UserType } from "@/types/user";
import { useMessage } from "./MessageProvider";
import { userLogout } from "@/services/userService";
import { getLocalStorageItem } from "@/utils/getLocalStorageItem";

interface AuthContextType {
  user: UserType | null;
  setUser: React.Dispatch<React.SetStateAction<UserType | null>>;
  refreshAuthentication: () => Promise<void>;
  logout: () => Promise<void>;
  isUserLoading: boolean;
}

// Create a context for authentication data
export const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<UserType | null>(null);
  const [isUserLoading, setIsUserLoading] = useState<boolean>(true);

  const { showMessage } = useMessage();

  // Function to log out the user
  async function logout() {
    try {
      // Send a request to the backend to log the user out
      await userLogout();

      // On successful logout, reset user state and clear local storage
      setUser(null);
      localStorage.removeItem("user");
      showMessage("success", "Logged out successfully");
    } catch (error) {
      // Log any errors to the console for debugging
      console.error("Logout failed:", error);
    }
  }

  // Function to refresh the authentication state
  async function refreshAuthentication() {
    try {
      setIsUserLoading(true);

      const response = await fetch(`${envConfig.publicServerUrl}/api/users/me`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error(`Failed to refresh authentication: ${response.statusText}`);
      }

      const data = await response.json();

      if (data?.user) {
        setUser(data.user);
        localStorage.setItem("user", JSON.stringify(data.user));
      } else {
        setUser(null);
        localStorage.removeItem("user");
      }
      setIsUserLoading(false);
    } catch (error) {
      console.error("Failed to refresh authentication:", error);
      setIsUserLoading(false);
    }
  }

  // Effect to call refreshAuthentication on component mount
  useEffect(() => {
    if (typeof window !== "undefined" && typeof window.document !== "undefined") {
      const storedUser = getLocalStorageItem("user");

      if (storedUser) {
        setUser(JSON.parse(storedUser));
      }

      refreshAuthentication();
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        refreshAuthentication,
        logout,
        isUserLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuthContext must be used within a AuthProvider");
  }
  return context;
};
