import envConfig from "@/config/envConfig";

type updateUserPayloadType = {
  fullName: string;
  email: string;
  phoneNumber?: string;
  country?: string;
  state?: string;
  city?: string;
  avatar?: any;
};

export const updateUser = async ({
  fullName,
  email,
  phoneNumber,
  country,
  state,
  city,
  avatar,
}: updateUserPayloadType) => {
  try {
    if (avatar === "delete") {
      await fetch(`${envConfig.publicServerUrl}/api/v1/users/remove-profile-picture`, {
        method: "POST",
        credentials: "include",
      });
    }

    const formData = new FormData();
    formData.append("fullName", fullName);
    formData.append("email", email);
    formData.append("phoneNumber", phoneNumber || "");
    formData.append("country", country || "");
    formData.append("state", state || "");
    formData.append("city", city || "");
    formData.append("image", avatar);

    const response = await fetch(`${envConfig.publicServerUrl}/api/v1/users/update-profile`, {
      method: "PATCH",
      body: formData,
      credentials: "include",
    });

    if (!response.ok) {
      const errRespData = await response.json();
      throw new Error(errRespData?.errors[0]?.message || "Failed to update user!");
    }

    const respData = await response.json();
    return respData;
  } catch (err) {
    throw err;
  }
};

export const deleteUser = async () => {
  try {
    const response = await fetch(`${envConfig.publicServerUrl}/api/v1/users`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    const respData = await response.json();
    if (!response.ok) {
      throw new Error(respData?.errors[0]?.message || "Something went wrong!");
    }

    return respData;
  } catch (err) {
    throw err;
  }
};

export const userLogout = async () => {
  try {
    const response = await fetch(`${envConfig.publicServerUrl}/api/users/logout`, {
      method: "POST",
      credentials: "include",
    });

    // Check if the response is successful
    if (!response.ok) {
      throw new Error(`Logout failed: ${response.statusText}`);
    }

    const respData = await response.json();
    return respData;
  } catch (err) {
    throw err;
  }
};

export const subscribeUser = async (email: string) => {
  try {
    const response = await fetch(`${envConfig.publicServerUrl}/api/subscribers`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });

    if (!response.ok) {
      throw new Error("Failed to subscribe");
    }

    return await response.json();
  } catch (error) {
    console.error(error);
    throw error;
  }
};
